<template>
  <b-card-code
    title="Events"
    no-body
  >
    <b-overlay
      :show="showOverlay"
      rounded="sm"
    >
    
    <b-col
      md="12"
      class="my-1"
    >
    <!--
      <b-button    
        variant="primary"
        class="mb-1 float-right"
        to="/create-course"
      >
            Create Lesson
      </b-button>
      -->
    </b-col>
    <b-table
      :fields="fields"
      :items="eventLists"
      responsive
      class="mb-0"
    >

      <!-- A virtual column -->
      <template #cell(Sr#)="data">
        {{ data.index + 1 }}
      </template>

      <!-- A custom formatted column -->
      <template #cell(name)="data">
        <span class="text-nowrap">{{ data.value }}</span>
      </template>

      <template #cell(id)="data">
        <b-row>
            <b-col lg="3" class="pb-2"><b-button variant="outline-primary" :to="'/find-event-student/'+data.value+''" size="sm"><b-icon icon="eye-fill"></b-icon></b-button></b-col>
        </b-row>  
              
     </template>
    </b-table>
    <!-- pagination -->
    
        <b-col>
      
      
    <record-count :to ='toRecordCount' :from='fromRecordCount' :total='totalRecordCount'></record-count>
    <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :base-url="path"
        :per-page="perPageRecord"
        :align="selected"
        @input = "changePage(currentPage)"
        class = "mb-4"
      />
    </b-col>
      
    </b-overlay>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { BTable, BProgress,BButton, BBadge, BDropdown, BDropdownItem, BPagination, BOverlay, BRow , BCol, BLink, BIcon} from 'bootstrap-vue'
import EventService  from '@/services/event.service.js';

export default {
  components: {
    BCardCode,
    BTable,
    BProgress,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BOverlay,
    BRow,
    BCol,
    BButton,
    BLink,
    BIcon
  },
  data() {
    return {
      fields: [
        // A virtual column that doesn't exist in items
        'Sr#',
        // A column that needs custom formatting
        { key: 'title', label: 'Title' },
        { key: 'start_date', label: 'Start Date' },
        { key: 'end_date', label: 'End Date' },
        { key: 'location', label: 'Location' },
        { key: 'available_slot', label: 'Available Slot' },
        { key: 'total_slot', label: 'Total Slot' },
        {key: 'booked_event_count', label: 'Booked Slot'},
        {key: 'id', label: 'Action'},
      ],
      eventLists: [],
      marginePage: 10,
      initialPage: 0,
      pageRange: 10,
      pageCount: 0,
      currentPage: 0,
      srCounter: 0,
      perPageRecord: 0,        
      toRecordCount: 0,
      fromRecordCount: 0,
      totalRecordCount: 0,
      selected: 'right',
      currentPage: 1,
      rows: 0,
      path: '',
      showOverlay: false,
    }
  },
  methods: {
    getEvents() {
        this.showOverlay = true;
        EventService.getAll(
            this.currentPage,
        ).then(response => {
            console.log(response.data.data.events)
            this.eventLists = response.data.data.events.data;
            this.rows = response.data.data.events.total;
            this.currentPage = response.data.data.events.current_page
            this.path = response.data.data.events.path
            this.pageCount = response.data.data.events.last_page;
            this.currentPage = response.data.data.events.current_page;
            this.perPageRecord = response.data.data.events.per_page;
            this.toRecordCount = response.data.data.events.to;
            this.fromRecordCount = response.data.data.events.from;
            this.totalRecordCount = response.data.data.events.total;
            this.srCounter = (this.currentPage-parseInt(1)) * this.perPageRecord;
            this.showOverlay = false;
        }).catch(error => {
            this.showOverlay = false;
            console.log(error);
        });
    },
    changePage(event) {
      this.getEvents(this.currentPage);
    },
    
  },
  created() {
    this.getEvents(this.currentPage);
  }
}
</script>
